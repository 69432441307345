import React from 'react';
import TopBar from './TopBar';
import MidBar from './MidBar';
import ServicesBar from './ServicesBar';
import Carousel from './Carousel';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import './styles.css'

const NewLayout = ({ children }) => {
  const location = useLocation();
  const isSettingsRoute = location.pathname === '/v2/setting';
  const isCoursePage = location.pathname.includes('/learning');

  const hideCarouselAndServicesBar = isSettingsRoute || isCoursePage;
  const coursePagePadding = {xs: 0, sm: 3}

  return (
    <div style={{ overflow: 'hidden', minHeight: '100vh' }}>
      <TopBar />
      {!isCoursePage && <MidBar />}
      {!hideCarouselAndServicesBar && (
        <>
          <Carousel />
          <ServicesBar />
        </>
      )}
      <Box sx={{ maxWidth: 1440, margin: '0 auto', width: '100%', paddingInline: isCoursePage ? coursePagePadding : { xs: 2, sm: 3 } }}>
        {children}
      </Box>
      {!isCoursePage && <Footer />}
    </div>
  );
};

export default NewLayout;