/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import ErrorOutlineIcon from '@material-ui/icons//ErrorOutline';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';

const MultiTabBlockPage = (props) => {

  React.useEffect(()=> {
    const tempVar = sessionStorage.getItem('isRoutingRestricted') 

    if (!tempVar) {
      sessionStorage.setItem('isRoutingRestricted', true)
      window.location.reload()
    }
  }, [])

  return (
    <div
      style={{ backgroundColor: '#2C365E', height: '100vh', display: 'flex' }}
    >
      <Dialog open={true}>
        <Fade in={true}>
          <div style={{ margin: 'auto', color: 'white', padding:30}}>
            <div style={{ display: 'flex' }}>
              <ErrorOutlineIcon
                style={{ margin: '0 auto', marginBottom: 20, fontSize: 50 }}
              />
            </div>
            <h4 style={{ fontFamily: 'Roboto' }}>
              medtigo is open in another window. This session has expired.
              Please use the app from the last accessed window.
            </h4>
            <div style={{ display: 'flex' }}>
              <button
                css={css`
                  color: white;
                  padding: 3px 40px;
                  margin-left: auto;
                  cursor: pointer;
                  border: 1px solid white;
                  background: none;
                  border-radius: 10px;
                  &:hover {
                    background-color: white;
                    color: #1b447a;
                    ease-in-out: 1;
                  }
                `}
                onClick={() => (window.location.href = 'https://medtigo.com/')}
              >
                Cancel
              </button>
            </div>
          </div>
        </Fade>
      </Dialog>
    </div>
  );
};

export default MultiTabBlockPage;
