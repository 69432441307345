import * as React from 'react';
import staffingServices from "../services/staffingServices";
import {useSelector} from "react-redux";
import LearningService from 'src/services/learningService';

export const StaffingContext = React.createContext({});

export const StaffingContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [tabrecord,setTabrecord]=React.useState(activeTab)
  const [jobsData, setJobsData] = React.useState([]);
  const [jobsDataUpdate,setJobsDataUpdate]=React.useState(true)
  const [bookData,setBookData]=React.useState([])
  const [bookDataRender,setBookDataRender]=React.useState(true)
  const user = useSelector((state) => state.account.user);
  const [isLoading, setIsLoading] = React.useState(false);
  const [intendeData,setIntendedData]=React.useState([])

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const jobsData = await staffingServices.getJobs();
      setJobsData(jobsData.Data);
    } catch (error) {
      console.log(error);
      setJobsData([]);
    }
    setIsLoading(false);
  };

  const intendedData=async ()=>{
try{
  const intendedData= await staffingServices.geIntendedJobs()
  setIntendedData(intendedData.data.data)

   setTimeout(()=>{

      setActiveTab(1)
    
  },0)
  setTimeout(()=>{
    setActiveTab(0)
    },0)

 
}catch(error){
console.log(error)
}
  }
  const saveData=async ()=>{
try{
  const bookmarkData= await staffingServices.getBookmarkJob()
  setBookData(bookmarkData.data.data)
}catch (error){
console.log(error)
}
  }
  

  React.useEffect(() => {
    user && fetchJobs()
   && intendedData()&&
    saveData()
  }, [user,jobsDataUpdate,bookDataRender]);



  const defaultContext = {
    activeTab,
    setActiveTab,
    isLoading,
    setJobsDataUpdate,
    jobsData,
    jobsDataUpdate,
    intendeData,
    bookData,
    bookDataRender,
    setBookDataRender
  };

  return (
    <StaffingContext.Provider
      value={defaultContext}
    >
      {children}
    </StaffingContext.Provider>
  );
};
