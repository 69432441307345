import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledMenu = withStyles({
  paper: {
    borderRadius: '2px',
    background: '#fff',
    color: '#1C5087',
    width: '150px !important',
    boxShadow: '0px 2px 4px 0px #00000040'
  }
})(props => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: '#EEF7FF',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    },
    margin: '0px !important',
    paddingRight: '20px',
    borderBottom: '1px solid cfecfe',
    fontFamily: 'Poppins',
    fontWeight: 600
  }
}))(MenuItem);

export default function HeaderMenu({ tabs, anchorEl, handleClose }) {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {tabs.map((tab, index) => (
        <StyledMenuItem key={index} onClick={() => {tab.onClick();handleClose()}}>{tab.value.toUpperCase()}</StyledMenuItem>
      ))}
    </StyledMenu>
  );
}
