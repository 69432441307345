import * as React from 'react';
import certificatesService from "../services/certificatesService";
import {updateCard} from "../actions/kanbanActions";
import {useSnackbar} from "notistack";
import RenewOrderServices from 'src/services/renewOrderService';
import { CertificateVariants, Partners } from 'src/appConstants';
import { useSelector} from 'react-redux';
import orderServices from 'src/services/orderServices';

export const CertificatesContext = React.createContext({});

export const CertificatesContextProvider = ({ children }) => {
  const user = useSelector((state) => state.account.user);
  const {enqueueSnackbar} = useSnackbar();
  const [drawerStatus, setDrawerStatus] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeCertificateData, setActiveCertificateData] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = React.useState(false);
  const [highlightedCertID, setHighlightedCertID] = React.useState(null);
  const [isACLSOrderExist, setACLSOrder] = React.useState(false);
  const [isBLSOrderExist, setBLSOrder] = React.useState(false);
  const [isPALSOrderExist, setPALSOrder] = React.useState(false);
  const [isASLSOrderExist, setASLSOrder] = React.useState(false);
  const [isNRPOrderExist, setNRPOrder] = React.useState(false);
  const [partnerUser, setPartnerUser] = React.useState(false); // FOR TEAMHEALTH USER
  const [noACLSCertificateExist, setNoACLSCertificate] = React.useState(false);
  const [noBLSCertificateExist, setNoBLSCertificate] = React.useState(false);
  const [noPALSCertificateExist, setNoPALSCertificate] = React.useState(false);
  const [noASLSCertificateExist, setNoASLSCertificate] = React.useState(false);
  const [noNRPCertificateExist, setNoNRPCertificate] = React.useState(false);

  const [isACLS_CME_available, setACLS_CME_Available] = React.useState(false);
  const [isBLS_CME_available, setBLS_CME_Available] = React.useState(false);
  const [isPALS_CME_available, setPALS_CME_Available] = React.useState(false);
  const [isASLS_CME_available, setASLS_CME_Available] = React.useState(false);
  const [isNRP_CME_available, setNRP_CME_Available] = React.useState(false);

  const [isACLS_Order_Hash, setACLS_Order_Hash] = React.useState(null);
  const [isBLS_Order_Hash, setBLS_Order_Hash] = React.useState(null);
  const [isPALS_Order_Hash, setPALS_Order_Hash] = React.useState(null);
  const [isASLS_Order_Hash, setASLS_Order_Hash] = React.useState(null);
  const [isNRP_Order_Hash, setNRP_Order_Hash] = React.useState(null);

  const [isACLS_Exist, setACLS_Exist] = React.useState(true);
  const [isBLS_Exist, setBLS_Exist] = React.useState(true);
  const [isPALS_Exist, setPALS_Exist] = React.useState(true);
  const [isASLS_Exist, setASLS_Exist] = React.useState(true);
  const [isNRP_Exist, setNRP_Exist] = React.useState(true);

  const [isFullAccess, setFull_Access] = React.useState(false);


  const fetchDummyCertificates = async () => {
    const dummyCertificates = await certificatesService.getDummyCertificates();
    return dummyCertificates
  };

  const handleSendEmail = async (emails, sendACopy, isCME, shouldEmailCmeCredits,shouldEmailCertificate) => {
    try {
      if (isCME) {
        if (shouldEmailCmeCredits) {
          await certificatesService.sendCertificateEmail(
            { recipients: emails.join(','), sendACopy, isCME: true },
            activeCertificateData['id']
          );
        }
        if (shouldEmailCertificate) {
          await certificatesService.sendCertificateEmail(
            { recipients: emails.join(','), sendACopy, isCME: false },
            activeCertificateData['nonCMECertId']
          );
        }
        enqueueSnackbar('Email(s) sent to recipients.', {
          variant: 'success'
        });
        return;
      }
      await certificatesService.sendCertificateEmail(
        {
          recipients: emails.join(','),
          sendACopy,
          isCME:
            activeCertificateData['type'] === 'CME' ||
            activeCertificateData['type'] === 'cme'
        },
        activeCertificateData['id']
      );
      enqueueSnackbar('Email(s) sent to recipients.', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar('Oops! An error occurred while sending emails.', {
        variant: 'error'
      });
    }

  };

  React.useEffect(() => {
    isCertificateExist();
  }, [])

  const isCertificateExist = async () => {
    if(user?Partners.includes(user.email.split('@')[1]):false){
      setPartnerUser(true);
      return;
    }

    const isFullAccessOrder = await orderServices.isFullAccessOrder();

    setFull_Access(isFullAccessOrder.isFullAccess);

    const PALS_Course_id = CertificateVariants.find(it => it.name == 'PALS').Course_id;
    const PALS_Order = await RenewOrderServices.isOrderItemWithValidHash(PALS_Course_id);
    setPALS_CME_Available(PALS_Order.isCMEValid);
    setPALS_Order_Hash(PALS_Order.hash);

    const ACLS_Course_id = CertificateVariants.find(it => it.name == 'ACLS').Course_id;
    const ACLS_Order = await RenewOrderServices.isOrderItemWithValidHash(ACLS_Course_id);

    setACLS_CME_Available(ACLS_Order.isCMEValid);
    setACLS_Order_Hash(ACLS_Order.hash);

    const BLS_Course_id = CertificateVariants.find(it => it.name == 'BLS').Course_id;
    const BLS_Order = await RenewOrderServices.isOrderItemWithValidHash(BLS_Course_id);
    setBLS_CME_Available(BLS_Order.isCMEValid);
    setBLS_Order_Hash(BLS_Order.hash);

    const NRP_Course_id = CertificateVariants.find(it => it.name == 'NRP').Course_id;
    const NRP_Order = await RenewOrderServices.isOrderItemWithValidHash(NRP_Course_id);
    setNRP_CME_Available(false);
    setNRP_Order_Hash(NRP_Order.hash);

    const ASLS_Course_id = CertificateVariants.find(it => it.name == 'ASLS').Course_id;
    const ASLS_Order = await RenewOrderServices.isOrderItemWithValidHash(ASLS_Course_id);
    setASLS_CME_Available(false);
    setASLS_Order_Hash(ASLS_Order.hash);

    const res = await certificatesService.ifCertificateExist();
    if (res.data.PALS_Exist.length <= 0) {
      setPALS_Exist(false);
      setNoPALSCertificate(true);
      setPALSOrder(PALS_Order.isValidOrder); 
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'PALS').id);
      if (renewHistory && renewHistory.length > 0) {
        setPALSOrder(true);
      }
    }
    if (res.data.ACLS_Exist.length <= 0) {
      setACLS_Exist(false);
      setNoACLSCertificate(true);
      setACLSOrder(ACLS_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'ACLS').id);
      if (renewHistory && renewHistory.length > 0) {
        setACLSOrder(true);
      }
    }
    if (res.data.BLS_Exist.length <= 0) {
      setBLS_Exist(false);
      setNoBLSCertificate(true);
      setBLSOrder(BLS_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'BLS').id);
      if (renewHistory && renewHistory.length > 0) {
        setBLSOrder(true);
      }
    }
    if (res.data.NRP_Exist.length <= 0) {
      setNRP_Exist(false);
      setNoNRPCertificate(true);
      setNRPOrder(NRP_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'NRP').id);
      if (renewHistory && renewHistory.length > 0) {
        setNRPOrder(true);
      }
    }

    if (res.data.ASLS_Exist.length <= 0) {
      setASLS_Exist(false);
      setNoASLSCertificate(true);
      setASLSOrder(ASLS_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'ASLS').id);
      if (renewHistory && renewHistory.length > 0) {
        setASLSOrder(true);
      }
    }
  }

  const defaultContext = {
    drawerStatus,
    setDrawerStatus,
    activeCertificateData,
    setActiveCertificateData,
    isEdit,
    setIsEdit,
    setIsEmailDialogOpen,
    isEmailDialogOpen,
    handleSendEmail,
    highlightedCertID,
    setHighlightedCertID,
    activeTab,
    setActiveTab,
    isACLSOrderExist,
    isBLSOrderExist,
    isPALSOrderExist,
    noACLSCertificateExist,
    noBLSCertificateExist,
    noPALSCertificateExist,
    noNRPCertificateExist,
    partnerUser,
    isACLS_CME_available,
    isBLS_CME_available,
    isPALS_CME_available,
    isACLS_Order_Hash,
    isBLS_Order_Hash,
    isPALS_Order_Hash,
    isCertificateExist,
    isFullAccess,
    isACLS_Exist,
    isBLS_Exist,
    isPALS_Exist,
    isNRP_Exist,
    isNRPOrderExist,
    isNRP_CME_available,
    isNRP_Order_Hash,
    noASLSCertificateExist,
    isASLS_Exist,
    isASLSOrderExist,
    isASLS_CME_available,
    isASLS_Order_Hash,
    fetchDummyCertificates
  };

  return (
    <CertificatesContext.Provider
      value={defaultContext}
    >
      {children}
    </CertificatesContext.Provider>
  );
};
