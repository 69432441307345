import React, { useState } from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, Tabs, Tab, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HeaderMenu from './HeaderMenu';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    fontSize: '16px',
    fontFamily: 'Poppins',
    marginBottom: '5px',
    boxShadow: '0px 4px 4px 0px #00000014',
  },
  toolbarContainer: {
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  bottomToolbar: {
    backgroundColor: '#fff',
    display: 'flex',
    fontFamily: 'Poppins',
    fontWeight: 500,
    paddingInline: 'auto'
  },
  flexGrow: {
    flexGrow: 1
  },
  divider: {
    width: '1px',
    height: 'inherit',
    backgroundColor: '#ddd'
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '9px'
  },
  tab: {
    color: '#000',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    height: '64px',
    minWidth: 'auto',
    width: 'auto',
    '&:last-child': {
      paddingRight: 0
    },
    '&.Mui-selected': {
      color: '#2872C1'
    }
  },
  leftSection: {
    display: 'flex',
    gap: '17px',
    [theme.breakpoints.down('xs')]: {
      gap: 10
    }
  },
  rightSection: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center'
  },
  tabIndicator: {
    color: '#2872C1',
    backgroundColor: '#2872C1',
    height: '3px',
    bottom: 0
  },
  menu: {
    color: '#2872C1',
    fontSize: 35
  }
}));

const tabs = [
  { value: 'home', label: 'Home', link: '/' },
  { value: 'news', label: 'News & Opinion', link: 'https://staging17.medtigo.com/news/' },
  { value: 'journal', label: 'Journal', link: 'https://staging2.journal.medtigo.com/' },
  { value: 'reference', label: 'Reference', link: 'https://staging17.medtigo.com/drugs/' },
  { value: 'education', label: 'Education', link: 'https://courses.medtigo.com/' },
  { value: 'services', label: 'Services', link: '/services' }
];

function MidBar() {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('services');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <AppBar className={clsx(classes.root)} position="static" elevation={0}>
      <div className={classes.toolbarContainer}>
        <Toolbar className={classes.bottomToolbar}>
          <div className={classes.leftSection}>
            <img
              src="https://medtigo.com/wp-content/uploads/2024/05/medtigo_2-1.svg"
              alt="Logo"
              height="50px"
              width="168px"
            />
          </div>
          <div className={classes.flexGrow}></div>
          {isMobile ? (
            <>
              <Typography style={{color: 'black', fontSize: '16px', fontWeight: 600, paddingInline: '12px'}}>SERVICES</Typography>
              <MenuRoundedIcon className={classes.menu} onClick={handleClick} />
              <HeaderMenu
                tabs={tabs}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
            </>
          ) : (
            <Tabs
              value={currentTab}
              onChange={handleTabsChange}
              textColor="secondary"
              classes={{ indicator: classes.tabIndicator }}
              className={classes.rightSection}
            >
              {tabs.map(tab => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  className={classes.tab}
                  href={tab.link}
                  onClick={(event) => {
                    event.preventDefault();
                    window.location.href = tab.link;
                  }}  
                />
              ))}
            </Tabs>
          )}
        </Toolbar>
      </div>
    </AppBar>
  );
}

export default MidBar;
