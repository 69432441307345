/** @jsx jsx */
/** @jsxFrag React.Fragment **/
import { Button, TextField, TextareaAutosize, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import certificatesTaskService from 'src/services/certificateTaskService';
import orderServices from 'src/services/orderServices';
import { jsx, css } from '@emotion/core';
import Typography from 'src/theme/typography';
import './style.css';
import { Chip, FormLabel, Grid } from '@material-ui/core/es';
import { useSnackbar } from 'notistack';
const useStyles = makeStyles(() => ({
  textFields: {
    marginBottom: 20,
    width: '100%'
  }
}));
function TaskResponseView() {
  const classes = useStyles();
  const [taskId, setTaskId] = useState("")
  const [comment, setComment] = useState("")
  const [file, setFile] = useState(null)
  const [certificateId, setCertificateId] = useState("")
  const [cursor, setCursor] = useState('pointer')
  const [isRequestSend, setIsRequestSend] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [files, setFiles] = React.useState([]);
  const [update, setUpdate] = React.useState(0);

  useEffect(() => {
    if (queryParams.get("Task_Id")) setTaskId(queryParams.get("Task_Id"))
    if (queryParams.get("Certificate_Id")) setCertificateId(queryParams.get("Certificate_Id"))
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('Comment', comment);
    formData.append('Task_Id', taskId);
    formData.append('Certificate_Id', certificateId);
    files.forEach(item=>{
      formData.append('file', item.file);
  })
    setCursor('not-allowed')

    await certificatesTaskService.addCertificateTaskResponse(formData)
      .then(data => {
        setIsRequestSend(true)
        enqueueSnackbar('Response Submitted!', {
          variant: 'success'
        });
      })
      .catch(err => {
        enqueueSnackbar('Failed to submit the response!', {
          variant: 'error'
        });
      })
      .finally(() => {
        setCursor('pointer')
      })
  }

  const handleFileInput=(inputFiles)=>{
      let newfiles=[];
       Array.from(inputFiles).forEach(file => {
        let newFile = {'file':file,fileName:file.name}
        newfiles.push(newFile)
      })
      setFiles([...files,...newfiles])
  }

  React.useEffect(()=>{
  },[update,files]);

  return <div
    css={{
      background: 'linear-gradient(#04002C 30%, #223363, #41699C)',
      height: '100vh',
      display: 'flex',
      flexDirection: "column",
      // width: 'device-width',
      fontFamily: 'sans-serif',
    }}>
    <div css={{
      backgroundColor: '#2C365E',
      height: '60px',
    }}>
      <img src='static/images/logo.png' css={{
        height: 'inherit',
        width: 'inherit',
        paddingLeft: '20px',
        paddingBottom: '2px',
        paddingTop: '2px'
      }}></img>
    </div>
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto auto',
      padding: '40px 90px',
      background: 'linear-gradient(#131948, #41699C)',
      fontSize: '18px',
      color: '#fff',
      textAlign: "left",
      gap: '20px',
      borderRadius: '3px',
      letterSpacing: '0.5px',
      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
      height:'80%',
      overflowY:'auto'
    }}>
      <div
      style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'10px'}}
      >

        <img
          src="/static/icons/response_icon.png"
          style={{ height: '50px' }}
        />
     <label css={{
            fontSize: 16}}>
          Upload Document
        </label>

      </div>
      <form
        className='task-response'
        style={{ width: '500px' }}
        onSubmit={handleSubmit}
      >
     <label css={{
            fontSize: 16}}>
          Add Note
        </label>
        <TextareaAutosize
          required
          fullWidth
          cols={3}
          rowsMin={5}
          placeholder="Add text here..."
          name="Comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          variant="outlined"
          className={classes.textFields}
          style={{ height:'90px', backgroundColor: 'transparent', color: 'white', padding: '10px', fontSize: '14px',outline:'none', marginTop:'10px', borderRadius:'5px', border:'1.4px solid #8f8f8f', overflow:'auto', resize:'vertical'  }}
        />
        <input
          color="primary"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.pdf"
          type="file"
          name="file"
          multiple
          onChange={event => {
            handleFileInput(event.target.files);
          }}
          id="certificate_file"
          style={{ display: 'none' }}
        />

        <label css={{
            fontSize: 16}}>
         Upload Attachments
        </label>
        <div
          css={css`
              border-radius:5px; 
              border:1.4px solid #8f8f8f;
              padding: 15px;
              height:60px;
              text-align: left;
              margin-top: 10px;
              align-items: center;
              
              display: flex;
              font-size: 14px;
              cursor: pointer;
              color: rgb(100, 169, 255);
              transition: all 0.5s;
              width:100%;
              &:hover {
                filter: brightness(110%);
              }
            `}
          onClick={() => document.getElementById('certificate_file').click()}
        >
           <p style={{textAlign:'center', width:'100%', fontWeight:'500px'}}>
          Choose File
           </p>
        </div>
        <div
          style={{ marginTop: '10px',fontSize: '12px' }}
        >
          {file ? (
            <>
              {file.name}
              <span
                onClick={() => {
                  setFile(null);
                }}
                css={css`
                    color: #dc3545;
                    cursor: pointer;
                    margin-left: 5px;
                    position: relative;
                    top: 1px;
                    font-size: 8px;
                    font-weight: 300;
                    display: ${isRequestSend ? 'none' : 'inline'};
                  `}
              >
                &#x2716;
              </span>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </div>
        <div
          style={{ height: '100px',overflowY: 'auto',overflowX: 'hidden' }}
        >
        <Grid container direction='column' spacing={1}>
          {
            files && files.map((itm, indx) => {
              return (
                <Grid item xs={3} md={3} >
                  <Chip variant='outlined' label={itm.file?.name} onDelete={() => {
                    let filesArr = files;
                    filesArr.splice(indx, 1);
                    setFiles(filesArr)
                    setUpdate(update + 1)
                  }} />
                </Grid>
              )
            })
          }
        </Grid>
        </div>
        <div
          css={css`{width:100%; margin:auto; display:flex; justify-content:center}`}>

        <Button
          type='submit'
          disabled={isRequestSend}
          css={{
            backgroundColor: '#0B66BF',
            color: "#fff",
            fontSize: '14px',
            height: '30px',
            border: 'none',
            borderRadius: '5px',
            padding: '5px 30px',
            fontWeight: 'bold',
            letterSpacing: '1px',
            fontFamily: 'sans-serif',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}>
          Submit
        </Button>
 
          </div>
      </form>

    </div>
  </div>
}

export default TaskResponseView
