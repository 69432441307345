import React from 'react';
import Logo from 'src/components/Logo';
import { Box, CircularProgress } from '@mui/material';

function NewSplashScreen() {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        zIndex: 2000,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',

      }}
    >
      <Logo style={{ width: 50 }} />
      <CircularProgress />
    </Box>
  );
}

export default NewSplashScreen;
