import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Link
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 20px !important',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: 30
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '5px 20px !important',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: 30,
    marginBottom: 10,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.background.light
    }
  },
  subMenu: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonSubLeaf: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: 30,
    marginTop: 5,
    fontWeight: theme.typography.fontWeightRegular,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:hover': {
      backgroundColor: theme.palette.background.light
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  iconTitle: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  active: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.light,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  },
  subMenu: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    listStyle: 'none',
    marginLeft: '0.8rem',
    borderLeft: '1px solid #fff',
    width: '80%',
    color: '#DFDFDF',
    marginBottom: '10px'
  },
  childItem: {
    // marginLeft: '1rem'
    padding: '0 1rem',
    justifyContent: 'flex-start !important'
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  imageIcon,
  target,
  sChild,
  childImage,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const history = useHistory();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (sChild) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          onClick={handleToggle}
          style={style}
          target={target}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          {imageIcon && (
            <img
              src={`/static/icons/${imageIcon}`}
              alt="Menu Icon"
              style={{
                marginRight: 12,
                width: 15,
                height: 17,
                marginLeft: 4
              }}
            />
          )}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>
          <ul className={classes.subMenu}>
            {sChild.map(child => (
              <li className={classes.childItem}>
                <Button
                  exact
                  component={target === '_blank' ? Link : RouterLink}
                  className={classes.buttonSubLeaf}
                  activeClassName={classes.active}
                  to={child.href}
                  style={{
                    justifyContent: 'flex-start',
                    padding: '0.25rem',
                    paddingLeft: '1rem'
                  }}
                >
                  {child.title}
                </Button>
              </li>
            ))}
          </ul>
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={target === '_blank' ? Link : RouterLink}
        exact
        isActive={() => {
          return window.location.href.includes(href);
        }}
        style={style}
        href={target === '_blank' ? href : {}}
        to={href}
        underline={'none'}
        target={target}
      >
        {Icon && <Icon className={classes.icon} size="20" />}

        {imageIcon && (
          <img
            src={`/static/icons/${imageIcon}`}
            alt="Menu Icon"
            style={{
              marginRight: 12,
              width: 15,
              height: 17,
              marginLeft: 4
            }}
          />
        )}
        <span className={classes.title}>{title}</span>

        {childImage && (
          <img
            src={`/static/icons/${childImage}`}
            alt="Menu Icon"
            style={{
              marginRight: 12,
              width: 12
              // marginLeft: 4
            }}
          />
        )}

        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  imageIcon: PropTypes.string,
  sChild: PropTypes.string,
  childImage: PropTypes.string
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
