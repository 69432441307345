import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, Badge, Divider, makeStyles, useMediaQuery, useTheme, MenuItem, Popper, Paper, ListItemIcon, MenuList ,ClickAwayListener} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { logout } from 'src/actions/accountActions';
import { AUTH_URL, SERVER_URL } from 'src/settings';
import { SearchBar } from './SearchBar';
import axios from 'axios';
import Popup from 'src/layouts/DashboardLayout/TopBar/Popup';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#1C5087',
    fontSize: '16px',
    fontFamily: 'Poppins',
    display: 'flex',
    width: '100%'
  },
  toolbarContainer: {
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  toolbar: {
    paddingInline: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    },
    backgroundColor: '#1C5087',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500
  },
  flexGrow: {
    flexGrow: 1
  },
  divider: {
    width: '1px',
    height: '30px',
    backgroundColor: '#ddd'
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '9px'
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '17px',
    [theme.breakpoints.down('xs')]: {
      gap: 10
    }
  },
  dateText: {
    textAlign: 'center',
    alignSelf: 'center',
    margin: 0
  },
  rightSection: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center'
  },
  accountMenu: {
    marginTop: theme.spacing(1)
  },
  accountMenuItem: {
    minWidth: 200
  },
  img: {
    height: '20px',
    width: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 15,
      width: 15
    }
  },
  iconButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'left',
  },
  userButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  popper: {
    width: '175px',
    height: '210px',
    backgroundColor: '#ffffff !important',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '1px',
    // marginTop: '5px'
    top:'-2px !important',
    zIndex:4,
    left:'65px !important',
    [theme.breakpoints.down('sm')]: {
      // Styles for tablet and mobile view
      width: '150px !important',
      height: '244px',
      left: '0px !important',
    
    },
  },
  menuItem: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight:'18px',
    color: '#3A3A3A',
    paddingLeft: '0px',
    minHeight:'0px',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  menuIcon: {
    color: '#1C5087'
    // marginRight: theme.spacing(2),
  },
  separator: {
    border: 0,
    borderTop: '1px solid #ddd',
    margin: '4px 0'
  }
}));

const MenuItemWithSeparator = ({
  icon,
  text,
  onClick,
  showSeparator
}) => {
  const classes = useStyles();
  return (
    <>
      <MenuItem
        className={classes.menuItem}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }}
      >
        <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>
        {text}
      </MenuItem>
      {showSeparator && <hr className={classes.separator} />}
    </>
  );
};

function TopBar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useSelector(state => state.account);
  const [profilePicture, setProfilePicture] = useState('/static/icons/account.svg');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const history = useHistory();
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const email = user.email; 
        const response = await axios.get(`https://staging2.medtigo.store/wp-json/user_cart_info/v2/cart/?email=${email}&request_mood=root`);
        const data = response.data;
  
        if (data.user_data_have) {
          setCartCount(data.number_of_item_in_cart);
        } else {
          setCartCount(0);
        }
      } catch (error) {
        setCartCount(0); 
      }
    };
  
    if (user.email) {
      fetchCartCount();
    } else {
      setCartCount(0); 
    }
  }, [user.email]); 

  useEffect(() => {
    if (user.profilePicture) {
      const pictureUrl = user.profilePicture.includes('http')
        ? user.profilePicture
        : `${SERVER_URL}${user.profilePicture}`;
      const isPlaceholder = pictureUrl === 'https://dev.medtigo.com/no-photo.jpg';
      setProfilePicture(isPlaceholder ? '/static/icons/account.svg' : pictureUrl);
    }
  }, [user]);

 const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  let username = user['email'].split('@')[0];
  username = username.charAt(0).toUpperCase() + username.slice(1);
  const wpToken = user['wp_token'];

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      window.location.href = AUTH_URL + '?path=logout';
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
    setOpen(false);
  };

  const handleNewsletter = () => {
    window.open('https://staging17.medtigo.com/news/', '_blank');
    setOpen(false);
  };

  const handleProfileSettings = () => {
    history.push('/v2/setting');
    setOpen(false);
  };

  const handleMyOrdersClick = () => {
    history.push('/v2/setting', { activeTab: 'orders' });
  };

  const cartUrl = `https://staging2.medtigo.store/cart?t=${wpToken}`;

  const handleCartClick = () => {
    window.location.href = cartUrl;
  };

  return (
    <>
    <AppBar className={clsx(classes.root)} position="static" elevation={0}>
    <div className={classes.toolbarContainer}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftSection}>
          <p className={classes.dateText}>{moment().format('MMMM D, YYYY')}</p>
          <Divider className={classes.divider} />
          <a href="/v2/state-licensing" className={classes.link}>
            <img
              src="/static/icons/notifications.svg"
              alt="Notifications"
              className={classes.img}
            />
            {!isMobile && 'Notification Center'}
          </a>
          <Divider className={classes.divider} />
          <a href="tel:+413-419-0592" className={classes.link}>
            <img
              src="/static/icons/phone.svg"
              alt="Phone"
              className={classes.img}
            />
            {!isMobile && '413-419-0592'}
          </a>
          <Divider className={classes.divider} />
          <div className={classes.link} onClick={() => setOpenSearchDialog(true)}>
            <img
              src="/static/icons/search.svg"
              alt="Search"
              className={classes.img}
              style={{cursor: "pointer"}}
            />
          </div>
        </div>
        <div className={classes.flexGrow}></div>

        <div className={classes.rightSection}>
          {!isSmallMobile && (
            <>
              <Divider className={classes.divider} />
              <a href={cartUrl} className={classes.link}>
                {!isMobile && 'Cart'}
                <Badge badgeContent={cartCount} color="error" showZero>
                  <ShoppingCartIcon fontSize="small" />
                </Badge>
              </a>
              <Divider className={classes.divider} />
            </>
          )}
           <div
            className={clsx(
              classes.link,
              classes.iconButton,
              classes.userButton
            )}
            ref={anchorRef}
            onClick={handleToggle}
          >
            {!isMobile &&
              (user.first_name
                ? `${user.first_name} ${user.last_name}`
                : username)}
             <img
               src={profilePicture}
              alt="User"
              height="27px"
              width="27px"
              style={{borderRadius:'50%'}}
            />
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
            disablePortal
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Paper className={classes.popper}>
                  <MenuList autoFocusItem={open} style={{padding:'10px'}}>
                    <MenuItemWithSeparator
                      icon={<img
                        src="/static/icons/useraccount.svg"
                        alt="user account"  
                        className={classes.img}
                      />}
                      text={
                        user['first_name']
                          ? `${user['first_name']} ${user['last_name']}`
                          : username
                      }
                      onClick={handleProfileSettings}
                      showSeparator
                    />
                     {isSmallMobile && (
                      <MenuItemWithSeparator
                        icon={
                          <Badge badgeContent={cartCount} color="error" showZero>
                            <ShoppingCartIcon fontSize="small" />
                          </Badge>
                        }
                        text="Cart"
                        onClick={handleCartClick}
                        showSeparator
                      />
                    )}
                    <MenuItemWithSeparator
                     icon={<img
                      src="/static/icons/mailicon.svg"
                      alt="mail"  
                      className={classes.img}
                    />}
                      text="Newsletter"
                      onClick={handleNewsletter}
                      showSeparator
                    />
                    <MenuItemWithSeparator
                      icon={<img
                        src="/static/icons/profileSetting.svg"
                        alt="profileSetting"  
                        className={classes.img}
                      />}
                      text="Profile Settings"
                      onClick={handleProfileSettings}
                      showSeparator
                    />
                     <MenuItemWithSeparator
                       icon={<img
                        src="/static/icons/myorders.svg"
                        alt="orders"  
                        className={classes.img}
                      />}
                      text="My Orders"
                      onClick={handleMyOrdersClick}
                      showSeparator
                    />
                    <MenuItemWithSeparator
                     icon={<img
                      src="/static/icons/logout.svg"
                      alt="logout"  
                      className={classes.img}
                    />}
                      text="Log Out"
                      onClick={handleLogout}
                      showSeparator={false}
                    />
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            )}
          </Popper>
        </div>
      </Toolbar>
    </div>
    </AppBar>
    <Popup />
    <SearchBar openSearchDialog={openSearchDialog} setOpenSearchDialog={setOpenSearchDialog} />
    </>
  );
}

export default TopBar;
