import React, {
  useRef,
  useState,
  useEffect
} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  Badge
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  Clock,
} from 'react-feather';
import {deleteNotifications, getNotifications, updateNotifications} from "src/actions/notificationsActions";
import alertPageInstance from 'src/services/alertPage';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon,
  alert: 'icons/11.png'
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 350,
    maxHeight: 'calc(100% - 125px)'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Notifications({userID}) {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notifications.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0)
  const handleOpen = async () => {
    const unreadNotificationIDs = notifications.filter(notification => notification.is_unread).map(notification => notification.id);
    setOpen(true);
    unreadNotificationIDs.length > 0 && await dispatch(updateNotifications(unreadNotificationIDs.join(',')));
  };

  const deleteNotification = async (id) => {
    const deleteNot = id ? id.toString() : notifications.map(notification => notification.id).join(',');
    await dispatch(deleteNotifications(deleteNot));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchNotificationCount = async () => {
    try{
      const {data: countData} = await alertPageInstance.getCount(userID)
      const totalCount = countData[0].OverDueTask + countData[0].PendingCount + countData[0].UpdateCount
      setNotificationCount(totalCount > 9 ? "9+" : totalCount)
    }
    catch(err){

    }

  }

  useEffect(() => {
    fetchNotificationCount()
  })

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={() => {
            history.push("/notifications")
          }}
        >
          <Badge badgeContent={notificationCount}
                 css={{
                   '.MuiBadge-badge': {
                     background: '#D84748',
                     color: '#FFF'
                   }
                 }}>
            <BellIcon/>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{paper: classes.popover}}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
            <span onClick={() => deleteNotification()} css={css`
              float: right;
              font-size: 12px;
              color: #6badff;
              margin-top: 5px;
              cursor: pointer;
              visibility: ${notifications.length === 0 ? 'hidden' : 'visible'};
              &:hover {
                text-decoration: underline;
              }
            `}>
              Clear All
            </span>
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <React.Fragment>
            <List
              className={classes.list}
              disablePadding
              css={{
                marginBottom: 30
              }}
            >
              {notifications.map((notification) => {
                const Icon = iconsMap.alert;
                return (
                  <ListItem
                    className={classes.listItem}
                    divider
                    key={notification.id}
                    onClick={() => {
                      history.push(`/certificates?completed=${notification['certificate_id']}`)
                    }}
                    css={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      backgroundColor: notification['is_unread'] ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                      borderBottomColor: notification['is_unread'] ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.12)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.07)',
                        '.MuiSvgIcon-root': {
                          visibility: 'visible',
                          opacity: 1
                        }
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                        css={css`
                          background-color: transparent;
                          height: 25px;
                          width: 25px;
                          border-radius: 0;
                          margin-left: 9px;
                        `}
                      >
                        <img width="100%" src={`/static/${Icon}`} alt="Icon"/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title || 'Expiring Soon'}
                      primaryTypographyProps={{variant: 'subtitle2', color: 'textPrimary'}}
                      secondary={notification.description || 'Expired'}
                    />
                    <ListItemAvatar onClick={(e) => {
                      e.stopPropagation();
                      deleteNotification(notification.id);
                    }}
                    css={{
                      textAlign: 'center',
                      minWidth: 24,
                    }}>
                      <CancelIcon className="cancel-icon" css={{ color: '#FFF', visibility: 'hidden', opacity: 0, transition: 'visibility 0.2s linear,opacity 0.2s linear' }}/>
                    </ListItemAvatar>
                  </ListItem>
                );
              })}
            </List>
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  );
}

export default Notifications;
