import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

function PaymentGuard({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { showSchedulingPayment, scheduling } = useSelector(state => state.account.user);

  if (!showSchedulingPayment || !scheduling) {
    enqueueSnackbar('Unauthorised Access!', {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      autoHideDuration: 1000,
    });
    return <Redirect to="/login" />;
  }

  return children;
}

PaymentGuard.propTypes = {
  children: PropTypes.any,
};

export default PaymentGuard;
