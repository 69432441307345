import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import orderServices from "../services/orderServices";


function CMEGuard({ children }) {
  const secret = children.props.children.props.location.state.secret;
  const course_id = children.props.children.props.location.state.course_id;
  orderServices.validateService({
    hash: secret,
    course: course_id
  }).then(
    res => {
      if (!secret) {
        return <Redirect to="/home" />;
      }
  
  
    }
  )

  return children;
}

CMEGuard.propTypes = {
  children: PropTypes.any
};

export default CMEGuard;
