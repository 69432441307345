import axios from 'src/utils/axios';

class StaffingServices {
  getJobs  = () => new Promise((resolve, reject) => {
    axios.get(`/staffing/jobs`)
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  addResumeData  = (values, fileUploadAction) => new Promise((resolve, reject) => {
    axios.post('/staffing/formSubmit/add', values, fileUploadAction)
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  getJobSave  = (id) => new Promise((resolve, reject) => {
 
    axios.get(`/staffing/save/${id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
 
  recordDropArrow  = (id) => new Promise((resolve, reject) => {
 
    axios.get(`/staffing/detailView/${id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  getBookmarkJob  = () => new Promise((resolve, reject) => {
  
    axios.get(`/staffing/getstatus`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  
  geIntendedJob  = (id) => new Promise((resolve, reject) => {
    axios.get(`/staffing/getIntendedstatus/${id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  geIntendedJobs  = (id) => new Promise((resolve, reject) => {
    axios.get(`/staffing/getIntendedjobs`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}



const staffingServices = new StaffingServices();
export default staffingServices;
