import * as React from 'react';
import { css, jsx } from '@emotion/core';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import {useSnackbar} from "notistack";
import * as Yup from 'yup';
import { Formik } from 'formik';
import CryptoJS from 'crypto-js';
import authService from 'src/services/authService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiTextField-root *:not(.MuiFormHelperText-root)': {
      color: '#fff',
      fontFamily: 'Poppins',
      fontSize: '18px',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#ddd',
    },
    'MuiIconButton-root': {
      color: '#fff',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderBottomColor: '#fff',
      }
    },
  }
})

export default function SessionEndPopup({open, onSubmitSuccess}) {
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  const account = useSelector((state) => state.account);

  return (
    <Dialog 
    open={open} 
    disableEnforceFocus   
    PaperProps={{
      style: {
        width: '500px',
        backgroundColor: 'rgb(27, 68, 122)',
        color: '#fff', 
        textAlign:'center'
      }
    }}>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <img src="/static/icons/session_end.svg" alt='Session End' />
        <Typography style={{fontSize: '20px', fontFamily: "Poppins", fontWeight: '600', lineHeight: '30px'}}>
           Your session has ended
        </Typography>        
        <Typography style={{fontSize: '16px', fontFamily: "Poppins", lineHeight: '30px'}}>
           Please Log In Again
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Required'),
            password: Yup.string()
              .max(255)
              .required('Required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values.email !== account.user.email) {
                enqueueSnackbar("Email mismatch. Please check and try again.", {
                variant: 'error',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top',
                }
              });
              return;
              }
              let Encrypted_Password = CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_ENCRYPTION_KEY).toString();
              let Encrypted_Email = CryptoJS.AES.encrypt(values.email, process.env.REACT_APP_ENCRYPTION_KEY).toString();

              await authService.loginWithEmailAndPassword(Encrypted_Email, Encrypted_Password);
              authService.refreshToken();

              enqueueSnackbar("Login Successful", {
                variant: 'success',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top',
                }
              });
              onSubmitSuccess();
            } catch (error) {
              const message = error || 'Something went wrong';
              setStatus({ success: false });
              setErrors({ submit: message });
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top',
                }
              });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.root}>
                <TextField
                   fullWidth
                   label="Email Address"
                   variant="standard"
                   name="email"
                   onBlur={handleBlur}
                   onChange={handleChange}
                   type="email"
                   value={values.email || ''}
                   error={Boolean(touched.email && errors.email)}
                   helperText={touched.email && errors.email}
                   inputProps={{ autoComplete: 'off' }}
                   style={{marginBottom: 3}}
                />
                <br />
                <TextField
                  fullWidth
                  label="Password"
                  variant="standard"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password || ''}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />      
                <Box
                  pt={3}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    disabled={isSubmitting}
                    disableElevation
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: '#2A7CCC',
                      fontFamily: 'Poppins',
                      color: 'white',
                      textTransform: 'none',
                      width: 92,
                      height: 35,
                      ':hover': {
                        backgroundColor: '#0B6BC8'
                      }
                    }}
                  >
                    LOG IN
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
