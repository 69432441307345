import React, {
  useRef,
  useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { logout } from 'src/actions/accountActions';
import {SERVER_URL, AUTH_URL, AUTH_QA} from "src/settings";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account.user);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      localStorage.setItem("logout", true);
      await dispatch(logout());
      // ###############################################
      // Old logout temporay commented 
      // ##############################################
      // window.location.href = AUTH_URL + 'logout';
    const url= window.location.origin;
      if (url == "https://dev.medtigo.com") {
        window.location.href = AUTH_QA + '?path=logout';
      }else{
        window.location.href = AUTH_URL + '?path=logout';
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };
  let username = account['email'].split('@')[0];
  username = username.charAt(0).toUpperCase() + username.slice(1);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account['profilePicture'] && account['profilePicture'] !== 'no-photo.jpg' ? (account['profilePicture'].includes('files') ? SERVER_URL + account['profilePicture'] : account['profilePicture']) : '/static/images/profile.png'}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {account['first_name'] ? `${account['first_name']} ${account['last_name']}` : username}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/*<MenuItem*/}
        {/*  component={RouterLink}*/}
        {/*  to="/social/profile"*/}
        {/*>*/}
        {/*  Profile*/}
        {/*</MenuItem>*/}
        <MenuItem
          component={RouterLink}
          to="/settings/"
        >
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout} id="logout-button">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
