import React from 'react';
import { Container, Box, Typography, CircularProgress } from '@mui/material';
import Page from 'src/components/Page';

export function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}/>
    </React.Fragment>
  );
}

function ClaimCmeSurvey() {
  return (
    <Page title="Certificates">
      <Container maxWidth={false} sx={{ height: '60vh' }}>
        <Box m={2} sx={{ borderRadius: '10px', textAlign: 'center' }}>
          <GradientCircularProgress />
          <Typography pt={2} sx={{ fontSize: '24px', fontWeight: 600, color: '#4B3A5A' }}>
            Please wait while we validate your access
          </Typography>
        </Box>
      </Container>
    </Page>
  );
}

export default ClaimCmeSurvey;
