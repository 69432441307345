/** @jsx jsx */
/** @jsxFrag React.Fragment **/
import { jsx } from '@emotion/core';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import * as React from 'react';
import axios from 'src/utils/axios';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

function UnsubscribeMail() {
    const {enqueueSnackbar} = useSnackbar();
    const [prefernce, setPreference] = React.useState({
        license_expiry_reminders:false,
        transactional_emails:false,
        update_emails:false,
        weekly_tasks_summary_emails:false,
    })
    const [uncheck,setUncheck]=React.useState(false);
    const [license_expiry_reminders,setlicense_expiry_reminders]=React.useState(false);
    const[email,setEmail]=React.useState(null);
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search) 

    React.useEffect(() => {
        if (queryParams.get("email")) setEmail(queryParams.get("email"))
    },[])

    React.useEffect(() => {
        if(email){
            getPreferences();
        }
    },[email])

    React.useEffect(() => {
    },[prefernce])

    const submitPrefernce = async () => {
        try {
            const res = await axios.post(`/UnsubscribeEmail/${email}`,prefernce);
            enqueueSnackbar("Preference Saved Successfully", {
                variant: 'success',
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                }
              });
        } catch (error) {
            enqueueSnackbar("Failed to save preference!", {
                variant: 'error',
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                }
              });
            console.log(error);
        }
    }

    const getPreferences=async()=>{
        try {
            const res = await axios.get(`/UnsubscribeEmail/${email}`);
            if(res){
            let obj={};
            obj.license_expiry_reminders=res.data.license_expiry_reminders;
            obj.transactional_emails=res.data.transactional_emails;
            obj.update_emails=res.data.update_emails;
            obj.weekly_tasks_summary_emails=res.data.weekly_tasks_summary_emails;
            setPreference(obj)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handlePrefs = (value,checked) => {
        setPreference({...prefernce,[value]:checked})
    }

    const uncheckAll=()=>{
        try {
            let obj={};
            obj.license_expiry_reminders=false;
            obj.transactional_emails=false;
            obj.update_emails=false;
            obj.weekly_tasks_summary_emails=false;
        setPreference(obj)

        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(()=>{
    },[prefernce])

    return <div css={{
        background: 'linear-gradient(#04002C 30%, #223363, #41699C)',
        height: '100vh',
        display: 'flex',
        flexDirection: "column",
        // width: 'device-width',
        fontFamily: 'sans-serif',
    }}>
        <div css={{
            backgroundColor: '#2C365E',
            height: '60px',
        }}>
            <img src='static/images/logo.png' css={{
                height: 'inherit',
                width: 'inherit',
                paddingLeft: '20px',
                paddingBottom: '2px',
                paddingTop: '2px'
            }}></img>
        </div>
        <div style={{ width: '70%', height: '100%', width: '100%', alignItems: 'center', display: 'flex', justifyContent: "center" }}>
            <div style={{ boxShadow: '3px 3px 3px rgba(0,0,0,0.3)', background: 'white', width: '40%', height: '80%', overflowY: "auto", padding: '30px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <img height={40} width={50} src="/static/icons/unsub_form.png" />
                    <Typography>Manage your email prefernces</Typography>
                    <div style={{ width: "100%", height: '0.7px', background: 'black', margin: '10px 0' }}>
                    </div>                    
                </div>

                <FormControl>
                    <FormGroup>
                        <FormControlLabel required control={<Checkbox checked={prefernce.license_expiry_reminders} className='checkbox' id='license_expiry_reminders' value={'license_expiry_reminders'} color='primary' onChange={(e) =>{handlePrefs(e.target.value,e.target.checked)}} />} label="License Expiry Reminder Emails" />

                        <FormControlLabel required control={<Checkbox checked={prefernce.transactional_emails} className='checkbox' id='transactional_emails' value={'transactional_emails'} color='primary' onChange={(e) =>{handlePrefs(e.target.value,e.target.checked)}} />} label="Transactional Emails" />

                        <FormControlLabel required control={<Checkbox checked={prefernce.update_emails} className='checkbox' id='update_emails' value={'update_emails'} color='primary' onChange={(e) =>{handlePrefs(e.target.value,e.target.checked)}} />} label='Update Emails' />

                        <FormControlLabel required control={<Checkbox checked={prefernce.weekly_tasks_summary_emails} className='checkbox' id='weekly_tasks_summary_emails' value={'weekly_tasks_summary_emails'} color='primary' onChange={(e) =>{handlePrefs(e.target.value,e.target.checked)}} />} label="Weekly Task Summary Emails" />

                    </FormGroup>
                    <Grid direction='row' style={{marginTop:'20px'}}>
                        <Button style={{ marginRight: '20px' }} onClick={submitPrefernce} variant='contained' color='primary'>Save my prefernces</Button>
                        <Button onClick={()=>uncheckAll()} variant='outlined' color='primary'>Unsubscribe All</Button>
                    </Grid>
                </FormControl>
            </div>
        </div>
    </div>
}

export default UnsubscribeMail
