import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  User as userIcon
} from 'react-feather';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { SERVER_URL } from '../../../settings';

const config = [
  {
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/home'
      },
      {
        title: 'Certificate Tracker',
        imageIcon: '11.png',
        href: '/certificates'
      },
      // {
      //   title: 'Compliance Check',
      //   imageIcon: '11.png',
      //   href: '/compliance'
      // },
      {
        title: 'Learning',
        imageIcon: '3.png',
        href: '/learning'
      },
      {
        title: 'Marketplace',
        imageIcon: 'license.png',
        href: '/redirect/marketplace',

        // href: 'https://medtigo.store',

        // target: '_blank',
        className: 'promotion-item'
      },
      {
        title: 'Staffing',
        imageIcon: '5.png',
        href: '/staffing',
        className: 'promotion-item'
      },
      {
        title: 'News & Community',
        imageIcon: '6.png',
        href: '/redirect/news&community',
        // href: 'https://medtigo.com',
        // target: '_blank',
        className: 'promotion-item'
      },
      {
        title: 'CME Compliance',
        imageIcon: 'cme_compliance_icon.png',
        href: '/cme-compliance',
        className: 'promotion-item'
      },
      {
        title: 'Journal',
        imageIcon: 'journal.png',
        href: '/redirect/journal',
        className: 'promotion-item'
      },
      {
        title: 'Courses',
        imageIcon: 'courses.png',
        href: '/redirect/courses',
        className: 'promotion-item'
      },
      {
        title: 'Settings',
        imageIcon: '16.png',
        href: '/settings/'
      }
    ]
  }
];
const recruiterConfig = [
  {
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/home/'
      },
      {
        title: 'Create Job',
        imageIcon: '5.png',
        href: '/createJobs/'
      },
      {
        title: 'View Job',
        imageIcon: '11.png',
        href: '/viewJob/'
      },
      {
        title: 'Create Client',
        icon: userIcon,
        href: '/createClient/'
      },
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        className={item.className || ''}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        target={item.target}
        imageIcon={item.imageIcon}
        css={{ pointerEvents: item.href === 'none' ? 'none' : 'auto' }}
        className={item.className || ''}
        sChild={item.children}
        childImage={item.childImage}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    img: {
      width: '130%'
    }
  }
}));

function NavBar({ openMobile, onMobileClose }) { 
  const classes = useStyles();
  const location = useLocation();
  const { user, isNotify } = useSelector(state => state.account);
  const [navConfig, setNavConfig] = React.useState(config);
  const [recruiterNavConfig , setRecruiterNavConfig] = React.useState(recruiterConfig);
  const [isNotification, setIsNotification] = React.useState(false)


  useEffect(() => {
    const isNotificationInURL = window.location.pathname.split('/').find(param => param === "notifications")
    if(isNotificationInURL){
      localStorage.setItem("isNotification", true)
      console.log("in true notification url");
      setIsNotification(true)
    } 
    else{
      localStorage.removeItem("isNotification")
      setIsNotification(false)
    }

  }, [])


  useEffect(() => {
    let localConfig = [...config];
    if (user.scheduling && localConfig[0].items.length == 10) {
      localConfig[0].items.splice(3, 0, {
        title: 'Schedule',
        imageIcon: '17.png',
        href: '/schedule/my-schedule',
        childImage: 'arrowDown.png',
        // Show payment Request Routes only for specific users
        children: user.showSchedulingPayment ? [
          {
            title: 'My Schedule',
            // href: '/schedule/my-schedule'
            href:"/schedule/dndcalender"
          },
          {
            title:'Group Schedule',
            href:"/schedule/team-calendar"
          },
          // {
          //   title: 'Shift Requests',
          //   href: '/schedule/shift-request'
          // },
          {
            title:'Availability',
            href:"/schedule/availability"
          },

          {
            title: 'Shift Requests',
            href: '/schedule/shift-request'
          },
          {
            title: 'Payment Request',
            href: '/schedule/payment-request'
          },
          {
            title: 'Payment History',
            href: '/schedule/payment-request-history'
          },
          // {
          //   title:'Dndcalender',
          //   href:"/schedule/dndcalender"
          // },
          // {
          //   title:'Dndcalenderr',
          //   href:"/schedule/dndcalenderr"
          // }
        ] : [
          {
            title: 'My Schedule',
            href:"/schedule/dndcalender"
          },
          {
            title:'Group Schedule',
            href:"/schedule/team-calendar"
          },
          {
            title:'Availability',
            href:"/schedule/availability"
          },
          {
            title: 'Shift Requests',
            href: '/schedule/shift-request'
          }
        ]
      });
    }
    if (user.scheduling && config[0].items.length == 11) {
        config[0].items.splice(7, 0, {
          title: 'Expenses',
          imageIcon: '17.png',
          href: '/invoice/meals-expense',
          childImage: 'arrowDown.png',
          children: [
            {
              title: 'Meals Expense',
              href: '/invoice/meals-expense'
            },
            {
              title: 'Site Expense',
              href: '/invoice/site-expense'
            },
            {
              title: 'Travel Expense',
              href: '/invoice/travel-expense'
            }
          ]
        });
    }

    if (user?.country !== 'United States') {
      setNavConfig(localConfig => {
        return [
          {
            items: localConfig[0].items.filter(
              item => item.title !== 'Staffing'
            )
          }
        ];
      });
    } else setNavConfig(localConfig);
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  let username = user['email'].split('@')[0];
  username = username.charAt(0).toUpperCase() + username.slice(1);

  if (user.wp_token) {
    navConfig[0].items.forEach(nav => {
      if (
        (nav.href.includes('market') ||
          nav.href.includes('content') ||
          nav.href.includes('https://medtigo.com')) &&
        !nav.href.includes('?t=')
      ) {
        nav.href = nav.href + '?t=' + user.wp_token;
      }
    });
  }
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/settings/">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={
                  user['profilePicture'] &&
                  user['profilePicture'] !== 'no-photo.jpg'
                    ? user['profilePicture'].includes('files')
                      ? SERVER_URL + user['profilePicture']
                      : user['profilePicture']
                    : '/static/images/profile.png'
                }
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user['first_name']
                ? `${user['first_name']} ${user['last_name']}`
                : username}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {user.role != 'recruiter' &&<Box p={2}>
          {navConfig.map((config, key) => (
            <List key={key}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>}
        {user.role == 'recruiter' &&<Box p={2}>
          {recruiterNavConfig.map((config, key) => (
            <List key={key}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <React.Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <div style={{display: `${isNotify ? "none" : ""}`}}>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          >
          {content}
        </Drawer>
      </Hidden>
      </div>
    </React.Fragment>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
