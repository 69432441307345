import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, makeStyles } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  root: {
    backgroundColor: '#fff',
    marginInline: 24,
    marginTop: 10,
    height: '38px',
    fontSize: '16px',
    fontFamily: 'Poppins',
    borderBottom: '1px solid #ccc',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginInline: 16,
    },
    [theme.breakpoints.down('xs')]: {
      height: '30px',
    }
  },
  tab: {
    color: '#000',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontSize: '16px',
    minHeight: '20px !important',
    padding: '0px 16px !important',
    minWidth: 'auto',
    width: 'auto',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    '&:first-child': {
      borderLeft: 'none',
      paddingLeft: '0px !important'
    },
    '&:last-child': {
      borderRight: 'none'
    },
    '&.Mui-selected': {
      color: '#000',
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 12px',
      fontSize: '14px',
    }
  }
}));

function ServicesBar() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [currentBottomTab, setCurrentBottomTab] = useState('certificates');
  const { scheduling } = useSelector(state => state.account.user);

  const navConfig = scheduling ? [
    { value: 'state licensing', label: 'State License', link: '/v2/state-licensing' },
    // { value: 'credentials', label: 'Credentials', link: '/v2/credentials' },
    { value: 'certificates', label: 'Certificates', link: '/v2/certificates' },
    { value: 'cme compliance', label: 'CME Compliance', link: '/v2/cme-compliance' },
    { value: 'schedule', label: 'Schedule', link: '/v2/schedule/payment-request' },
    // { value: 'job board', label: 'Job Board', link: '/v2/job-board' }
  ] : [
    { value: 'state licensing', label: 'State License', link: '/v2/state-licensing' },
    { value: 'certificates', label: 'Certificates', link: '/v2/certificates' },
    { value: 'cme compliance', label: 'CME Compliance', link: '/v2/cme-compliance' },
  ]

  useEffect(() => {
    const currentTab = navConfig.find(tab => tab.link === location.pathname);
    if(location.pathname === '/v2/state-compliance'){
      setCurrentBottomTab('cme compliance');
    }
    if(location.pathname.includes('/v2/state-licensing')){
      setCurrentBottomTab('state licensing');
    }
    if(location.pathname.includes('schedule')){
      setCurrentBottomTab('schedule');
    }
    if (currentTab) {
      setCurrentBottomTab(currentTab.value);
    }
  }, [location.pathname]);

  const handleBottomTabChange = (event, newValue) => {
    const newTab = navConfig.find(tab => tab.value === newValue);
    if (newTab) {
      history.push(newTab.link);
    }
  };

  return (
    <div className={classes.toolbarContainer}>
    <Box className={classes.root}>
      <Tabs
        value={currentBottomTab}
        onChange={handleBottomTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {navConfig.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            className={classes.tab}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
    </div>
  );
}

export default ServicesBar;
